.partners {
  position: relative;
  z-index: 50;
  padding-bottom: 80px;

  .exchange-logos {
    padding: 0 50px;
  }

  .partnerhead {
    font-family: 'Ebold';
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 135%;
    text-align: center;
    color: #EBEFF5;
    margin-bottom: 80px;
  }

  .inner-logos {

    // max-width: 1400px;
    // margin: 0 auto;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    img {
      cursor: pointer;
    }
  }

  .gradient-utilityb {
    display: block !important;
    position: absolute;
    top: -300px;
    left: 0px;
    z-index: -1;
  }

  .exchange-logos {
    margin-top: 116px;
  }

  h6 {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    padding: 10px 20px;
    padding-left: 10px;
    font-size: 14px;
    font-style: normal;
    border-radius: 180px;
    background: #EDEDED;
    color: #141519;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    transition: 0.3s linear;
    white-space: nowrap;

    .main-img {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      background-color: #141519;
      transition: 0.3s linear;
      flex-shrink: 0;

      svg {
        transition: 0.3s linear;

        path {
          transition: 0.3s linear;
        }
      }
    }

    &:hover {
      background: #141519;
      color: #fff;

      .main-img {
        background-color: #fff;
      }

      svg {
        path {
          fill: #141519;
        }
      }
    }
  }
  .slick-slide {
    margin: 0 20px; /* Adds horizontal margin around each slide */
  }

}

.main-heading-partners {
  margin-bottom: 60px;
  position: relative;
  z-index: 50;
  padding-top: 80px;

  h6 {
    color: #141519;
    text-align: center;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: capitalize;
    margin-bottom: 25px;
  }

  p {
    color: #828282;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
}




@media (max-width:1024px) {
  .partners .exchange-logos {
    margin-top: 50px;
  }
}



@media (max-width:1024px) {
  .partners .partnerhead {
    font-size: 34px;
  }

  .partners .partnerhead {
    margin-bottom: 40px;
  }

  .partners .gradient-utilityb {
    display: none !important;
  }

  .partner-left {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .slick-slide {
    margin: 0 5px;
  }

  .partners .exchange-logos {
    padding: 0 20px !important;
  }

}




.marquee {
  --duration: 30s;
  --gap: 1rem;

  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);

}

.new-design {
  border: 2px solid #2E333D;
  border-radius: 20px;
  padding: 60px 0;
  position: relative;
  // --duration: 120s;
  // --gap: 15rem !important;
}

.view-btn {
  position: absolute;
  bottom: 14px;
  right: 26px;
  background: linear-gradient(92.88deg, #FF3701 2.4%, #F6BB08 97.6%), #FA7E05;
  border-radius: 50px;
  padding: 5px 10px;
  border: none;
  font-style: normal;
  font-family: "Ebold";
  font-size: 14px;
  line-height: 135%;
  color: #FFFFFF;
}


.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll var(--duration) linear infinite;


}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee__group h4 {
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 1rem;
  border: 1px solid #ccc;
  padding: 3rem;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - var(--gap)));
  }
}


.logoallshows {
  background: rgba(0, 0, 0, 0.95);

  .modal-dialog {
    max-width: 700px;
  }

  .modal-content {
    background: #01060E;
    border: 2px solid #2E333D;
  }

  .modal-body {
    padding: 40px;
    height: 500px;
    overflow: hidden;
    overflow-y: auto;

    .inner-logos {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 50px 0;
    }
  }

  .close-btn {
    position: absolute;
    bottom: -6%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(92.88deg, #FF3701 2.4%, #F6BB08 97.6%), #FA7E05;
    border-radius: 50px;
    padding: 5px 10px;
    border: none;
    font-style: normal;
    font-family: "Ebold";
    font-size: 14px;
    line-height: 135%;
    color: #FFFFFF;
  }
}

@media (max-width:1024px) {

  // .new-design{
  //   --duration: 120s !important;
  //   --gap: 5rem !important;
  // }
  .marquee {
    --duration: 40s !important;
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}


@media (max-width:1024px) {
  .main-heading-partners h6 {
    font-size: 28px !important;
  }

  .main-heading-partners p {
    font-size: 16px;
  }
}


@media (max-width:1024px) {
  .marquee__group h6 {
    font-size: 18px !important;
  }

  .main-heading-partners {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width:600px) {
  .marquee__group h6 {
    font-size: 14px !important;
    padding: 10px !important;
  }

  .marquee__group h6 .main-img {
    width: 40px;
    height: 40px;
    padding: 5px;
  }
  .partners .slick-slide{
    margin: 0 10px;
  }
}