.main-navbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(12px);
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .social-icons{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 6.7px;
            a{
                width: 29.297px;
                height: 29.297px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #EDEDED;
                border-radius: 50%;
                overflow: hidden;
                transition: 0.3s linear;
                svg{
                    transition: 0.3s linear;
                   path{
                    transition: 0.3s linear;
                   }
                }
                &:hover{
                    background-color: #141519;
                    svg{
                        path{
                            fill: #EDEDED;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:600px){
    .main-navbar .parent .main-logo{
        max-width: 120px;
        width: 100%;
    }
}