.features{
    padding: 80px 0;
    .main-heading{
        margin-bottom: 60px;
        h6{
            color: #141519;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: capitalize;
        }
    }
    .bottom-content{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
        gap: 20px;
        .single-card{
            border-radius: 20px;
            background: rgba(237, 237, 237, 0.40);
            padding: 25px 20px;
            .circle-img{
                border-radius: 160px;
                background: #141519;
                width: 52px;
                height: 52px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 30px;
                transition: 0.3s linear;
                svg{
                    transition: 0.3s linear;
                    path{
                        transition: 0.3s linear;
                    }
                }
            }
            h6{
                color: #141519;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                margin-bottom: 10px;
                transition: 0.3s linear;
            }
            p{
                color: #828282;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                transition: 0.3s linear;
            }
            transition: 0.3s linear;
            &:hover{
                background: #141519;
                .circle-img{
                    background: #fff;
                    svg{
                        path{
                            fill: #141519;
                        }
                    }
                }
                h6, p{
                    color: #fff;
                }
            }
        }
    }
}

@media (max-width:600px){
    .features .main-heading h6{
        font-size: 28px;
    }
}