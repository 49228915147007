.easytouse {
    padding: 80px 0;
    background: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 570px;

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 107px;

        .left-side {
            max-width: 590px;
            width: 100%;

            .main-content {
                h4 {
                    color: #141519;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%;
                    text-transform: capitalize;
                    margin-bottom: 10px;
                }

                p {
                    color: #828282;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    text-transform: capitalize;
                }
            }
        }

        .right-side {
            flex: 1;
            .wrapper-img{
                position: relative;
                .default-image, .hover-image {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translate(0, -50%);
                    
                    transition: opacity 0.5s ease;
                  }
                  
                  .hover-image {
                    opacity: 0;
                  }
                  
                  
            }
            .wrapper-img:hover .default-image {
                opacity: 0;
              }
              
              .wrapper-img:hover .hover-image {
                opacity: 1;
              }
        }
    }
}

@media (max-width:600px){
    .easytouse{
        height: auto;
    }
    .easytouse .parent{
        flex-direction: column;
        gap: 50px;
    }
    .easytouse .parent .left-side .main-content h4{
        font-size: 28px;
    }
    .easytouse .parent .left-side .main-content p{
        font-size: 16px;
    }
    .easytouse .parent .right-side .wrapper-img .default-image, .easytouse .parent .right-side .wrapper-img .hover-image{
        position: static;
        transform: unset;
        pointer-events: none;
    }
    .easytouse .parent .right-side .wrapper-img:hover .default-image{
        opacity: 1;
    }
    .easytouse .parent .right-side .wrapper-img .hover-image{
        display: none;
    }
}