.main-banner {
    min-height: 100vh;
    position: relative;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

    .banner-bg {
        position: absolute;
        bottom: 0;
        right: 0;
        pointer-events: none;
        z-index: -1;
        height: 100%;
    }

    .spline-abs {
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translate(0%, -50%);
    }

    .parent {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .left-side {
            max-width: 655px;
            width: 100%;
            position: relative;
            z-index: 99;

            .main-content {
                h4 {
                    color: #141519;
                    font-size: 54px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%;
                    text-transform: uppercase;
                    margin-bottom: 30px;
                }

                p {
                    color: #828282;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    text-transform: capitalize;
                    margin-bottom: 50px;
                }

                .downloads {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 20px;

                    a {
                        transition: 0.3s linear;

                        &:hover {
                            filter: drop-shadow(0px 0px 28px rgba(0, 0, 0, 0.18));
                        }
                    }
                }
            }
        }

        // .right-side{
        //     flex: 1;
        // }
    }
}

@media (min-width:1600px) {
    .main-banner {
        min-height: auto;
        padding-top: 209px;
        padding-bottom: 130px;
    }
}

@media (max-width:600px) {
    .set-spline-mobile {
        display: none;
    }

    .main-banner {
        padding: 50px 0;
        padding-top: 120px;
        min-height: calc(100vh - 170px);
    }

    .main-banner .parent .left-side .main-content h4 {
        font-size: 28px;
    }

    .main-banner .parent .left-side .main-content p {
        font-size: 16px;
    }

    .dnoneformobile {
        display: none;
    }

    .main-banner .parent {
        flex-direction: column;
        gap: 50px;
    }

    .main-banner .set-spline-mobile {
        canvas {
            width: 350px !important;
            height: 320px !important;
        }
    }

    .mobile-images {
        display: flex !important;
        position: relative;
        justify-content: flex-start;
        align-items: center;

        .bannermbl1 {
            position: absolute;
            width: 200px;
            top: 0;
            right: 0;
            animation: moveUpDown 2s infinite alternate;
        }

        .bannermbl2 {
            width: 300px;
            animation: moveUpDown 3s infinite alternate;
        }
    }

    .main-banner .parent .right-side {
        width: 100%;
    }
    .main-banner .banner-bg{
        object-fit: cover;
    }
}

.bannermbl2 {
    animation-delay: 2.5s;
}
.bannermbl1 {
    animation-delay: 2.5s;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(25px);
    }
}
