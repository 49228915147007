.main-footer{
    padding-bottom: 15px;
    .top-section{
        padding: 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 25px;
        .social-icons{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 6.7px;
            a{
                width: 29.297px;
                height: 29.297px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #EDEDED;
                border-radius: 50%;
                overflow: hidden;
                transition: 0.3s linear;
                svg{
                    transition: 0.3s linear;
                   path{
                    transition: 0.3s linear;
                   }
                }
                &:hover{
                    background-color: #141519;
                    svg{
                        path{
                            fill: #EDEDED;
                        }
                    }
                }
            }
        }
    }
    .bottom-section{
        
        .parent{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .allrightreserved{
            color: #828282;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .right-side{
            a{
                color: #828282;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
            }
            span{
                color: #828282;
                margin: 0 8px;
            }
        }
    }
}

@media (max-width:600px){
    .main-footer .bottom-section .parent{
        flex-direction: column-reverse;
        gap: 15px;
    }
}