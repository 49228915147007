.last-section {
    padding: 90px 0;
    background: #F8F8F8;



    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;
        border: 1px solid #D0D0D1;
        background: #141519;
        height: 440px;
        padding-right: 70px;
        position: relative;
        z-index: 99;

        .last-section-layer {
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            z-index: -1;
        }

        .right-side {
            max-width: 555px;
            width: 100%;

            .main-content {
                h4 {
                    color: #FFF;
                    font-size: 33px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%;
                    text-transform: capitalize;
                    margin-bottom: 14px;
                }

                p {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    margin-bottom: 44px;
                }

                .downloads {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 20px;
                    a{
                        transition: 0.3s linear;
                        &:hover{
                            filter: drop-shadow(0px 0px 28px rgba(0, 0, 0, 0.75));
                        }
                    }
                }
            }
        }

        .left-side {
            flex: 1;
        }
    }
}

@media (max-width:600px) {
    .last-section .parent {
        padding-right: 0;
        padding: 15px;
        flex-direction: column-reverse;
        height: auto;
        gap: 50px;
    }

    .last-section .parent .right-side .main-content h4 {
        font-size: 26px;
        font-weight: 500;
    }

    .last-section .parent .right-side .main-content p {
        font-size: 16px;
    }
}