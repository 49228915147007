.bg-f8f8f8 {
    background: #F8F8F8;
}

.twice {
    padding: 50px 0;

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 135px;

        .left-side {
            max-width: 590px;
            width: 100%;

            .main-content {
                h4 {
                    color: #141519;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%;
                    text-transform: capitalize;
                    margin-bottom: 10px;
                }

                p {
                    color: #828282;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    text-transform: capitalize;
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .bottom-twice {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 40px;
                .single{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 8px;
                    .circle-img{
                        flex-shrink: 0;
                    }
                }

                a {
                    .single {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        gap: 8px;

                        .circle-img {
                            border-radius: 160px;
                            background: #141519;
                            width: 52px;
                            height: 52px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        p {
                            color: #141519;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 130%;
                        }
                    }
                }
            }
        }

        .right-side {
            flex: 1;
        }
    }
}

@media (max-width:600px){
    .twice .parent{
        flex-direction: column;
        gap: 50px;
    }
    .twice .parent .left-side .main-content h4{
        font-size: 28px;
    }
    .twice .parent .left-side .main-content p{
        font-size: 16px;
    }
    .twice .parent .left-side .bottom-twice{
        gap: 20px;
        justify-content: space-between;
    }
    .twice.bg-f8f8f8 .parent{
            flex-direction: column-reverse;
    }
    .inmobiledirectionchnage .parent{
        flex-direction: column-reverse !important;
    }
}