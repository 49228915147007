.privacypolicy {
    padding: 150px 0px 100px;

    .tophead {
        color: #141519;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        text-transform: uppercase;
        margin-bottom: 40px;
    }

    a{
        color: #141519;
        text-decoration: underline !important;
    }

    .privacypara {
        color: #141519;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        margin-bottom: 30px;
        strong{
            color: #141519;
        }
    }
    ul{
        list-style-type: disc;
        margin-left: 20px;
        li{
            color: #141519;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            margin-bottom: 10px;
            strong{
                color: #141519;
            }
        }
    }

    .midhead {
        color: #141519;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}

@media(max-width:600px){
    .privacypolicy{
        padding: 120px 0px 50px;
    }
    .privacypolicy .tophead{
        font-size: 38px;
        margin-bottom: 30px;
    }
    .privacypolicy .privacypara{
        font-size: 16px;
        margin-bottom: 20px;
    }
    .privacypolicy .midhead{
        font-size: 18px;
        margin-bottom: 10px;
    }
}